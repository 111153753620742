import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["card", "nextBtn", "prevBtn", "startAssessmentBtn"];

  connect() {
    this.currentCardIndex = 0;

    if (this.cardTargets.length > 0) {
      this.cardTargets[this.currentCardIndex].classList.add('active');
    }
  }

  goToNextCard() {
    if (this.currentCardIndex < this.cardTargets.length - 1) {
      this.cardTargets[this.currentCardIndex].classList.remove('active');
      this.currentCardIndex++;
      this.cardTargets[this.currentCardIndex].classList.add('active');
    }
  }

  goToPreviousCard() {
    if (this.currentCardIndex > 0) {
      this.cardTargets[this.currentCardIndex].classList.remove('active');
      this.currentCardIndex--;
      this.cardTargets[this.currentCardIndex].classList.add('active');
    }
  }
  skipAssessment() {
    if (confirm('Are you sure you wish to begin your exam now?')) {
      window.location.href = '/admin/controls';
    }
  }

  startAssessment() {
    if (confirm('Are you sure you wish to begin your exam now?')) {
      window.location.href = '/admin/controls';
    }
  }
}
