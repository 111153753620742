import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["notifyProgramModal"];

  openNotifyProgramModal(e) {
    e.preventDefault();
    e.stopImmediatePropagation();
    this.notifyProgramModalTarget.classList.remove("hidden");
  }

  hideNotifyProgramModal(e) {
    e.preventDefault();
    e.stopImmediatePropagation();
    this.notifyProgramModalTarget.classList.add("hidden");
  }
}