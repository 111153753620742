import { Controller } from "@hotwired/stimulus";
import React from "react";

export default class extends Controller {
  static targets = ["synopsisModal", "learningObjectivesModal", "documentTitle",
  "documentSynopsis", "documentLO", "documentTitleLOModal",
  "MODMapModal", "documentTitleMMModal",
  "modMap", "documentIntegration",
  "basketModal", "addCourseModal", "addTodoModal",
  "todoModal", "prereqTodoModal", "documentIdInput",
  "subservientIdInput", "modalSubservientSubtitle", "modalSubtitle",
  "modalPrerequisiteSubtitle", "clearNoteField", "clearDateField",
  "clearSubNoteField", "clearSubDateField", "clearPreNoteField", "clearPreDateField", "quizModal", "documentTitleQuizModal", "questionsAnsweredQuizModal","percentCorrectQuizModal",
  "saveQuizTodoModal", "quizName", "quizId","resetModal"];

  connect() {
    this.handleKeydown = this.handleKeydown.bind(this);
    document.addEventListener('keydown', this.handleKeydown);
  }

  disconnect() {
    document.removeEventListener('keydown', this.handleKeydown);
  }

  handleKeydown(event) {
    if (this.hasQuizModalTarget && !this.quizModalTarget.classList.contains('hidden')) {
      let cancelButton = this.quizModalTarget.querySelector('.cancel-button-class');
      if (event.key === 'Escape') {
        this['hideQuizModal'](event);
      }
    }

    if (this.hasSaveQuizTodoModalTarget && !this.saveQuizTodoModalTarget.classList.contains('hidden')) {
      this.handleModalKeydown(event, this.saveQuizTodoModalTarget, 'hideSaveQuizTodoModal');
    }

    if (this.hasSynopsisModalTarget && !this.synopsisModalTarget.classList.contains('hidden')) {
      let cancelButton = this.synopsisModalTarget.querySelector('.cancel-button-class');
      if (event.key === 'Escape') {
        this['hideSynopsisModal'](event);
      }
    }

    if (this.hasLearningObjectivesModalTarget && !this.learningObjectivesModalTarget.classList.contains('hidden')) {
      let cancelButton = this.learningObjectivesModalTarget.querySelector('.cancel-button-class');
      if (event.key === 'Escape') {
        this['hideLearningObjectivesModal'](event);
      }
    }

    if (this.hasMODMapModalTarget && !this.MODMapModalTarget.classList.contains('hidden')) {
      let cancelButton = this.MODMapModalTarget.querySelector('.cancel-button-class');
      if (event.key === 'Escape') {
        this['hideModMapModal'](event);
      }
    }
  }

  handleModalKeydown(event, modal, hideModalMethod) {
    let submitButton = modal.querySelector('input[type="submit"]');
    let cancelButton = modal.querySelector('.cancel-button-class');

    if (event.key === 'Enter') {
      // Trigger the submit button click event
      submitButton.click();
    } else if (event.key === 'Escape') {
      // Trigger the cancel button click event
      this[hideModalMethod](event);
    }
  }

  openSynopsisModal(event){
    this.documentTitleTarget.innerHTML=event.params.title
    this.documentSynopsisTarget.innerHTML=event.params.synopsis
    this.documentIntegrationTarget.innerHTML=""
    if(event.params.documentKind!=='illness_script') {
      let finalDiagnosis = event.params.finalDiagnosis.length !== 0 ?
        "<div> <hr/> Final Diagnosis: " + event.params.finalDiagnosis.map((final_diagnosis) => {
          return `<span class="final-diagnosis"> ${final_diagnosis} </span>`
        }) + "</div>" : ""
      let clinicalExcellence = event.params.clinicalExcellence.length !== 0 ?
        "<div> <hr/> Clinical Excellence Curricular Thread: " + event.params.clinicalExcellence.map((obj) => {
          return `<span class="clinical-excellence">${obj}</span>`
        }) + "</div>" : ""
      let basicScienceDiscipline = event.params.basicScienceDisciplines.length !== 0 ?
        "<div><hr/>Basic Science Curricular Thread: " + event.params.basicScienceDisciplines.map((obj) => {
          return `<span class="basic_science_discipline">${obj}</span>`
        }) + "</div>" : ""
      this.documentIntegrationTarget.innerHTML = finalDiagnosis.replaceAll(',', '') + clinicalExcellence.replaceAll(',', '') + basicScienceDiscipline.replaceAll(',', '')
    }
    this.synopsisModalTarget.classList.remove("hidden");
  }

  hideSynopsisModal(){
    this.synopsisModalTarget.classList.add("hidden");
  }

  openBasketModal(){
    this.basketModalTarget.classList.remove("hidden");
  }

  hideBasketModal(){
    this.basketModalTarget.classList.add("hidden");
  }

  openLearningObjectivesModal(event){
    this.documentTitleLOModalTarget.innerHTML=event.params.title
    const searchText = event.params.searchText
    const renderedLearningObjectives = event.params.lo.map((lo) => {
      if (searchText !== "" && lo.toLowerCase().includes(searchText.toLowerCase())) {
        const regex = new RegExp(searchText, 'gi');
        lo = lo.replace(regex, (match) => `<span class="search-hit">${match}</span>`);
      }
      return `
        <div class="doc-learning-objective">
          <div class="learning-objective-arrow"></div>
          <div class="learning-objective-content">
            ${lo}
          </div>
        </div>
      `;
    }).join("");

    this.documentLOTarget.innerHTML=renderedLearningObjectives
    this.learningObjectivesModalTarget.classList.remove("hidden");
  }

  hideLearningObjectivesModal(){
    this.learningObjectivesModalTarget.classList.add("hidden");
  }

  openModMapModal(){
    this.documentTitleMMModalTarget.innerHTML=event.params.title
    this.modMapTarget.innerHTML=`<img src="${event.params.mm}"></img>`
    this.MODMapModalTarget.classList.remove("hidden");
  }

  hideModMapModal(){
    this.MODMapModalTarget.classList.add("hidden");
  }

  openAddCourseModal() {
    this.addCourseModalTarget.classList.remove("hidden");
  }

  hideAddCourseModal() {
    this.addCourseModalTarget.classList.add("hidden");
  }

  openAddTodoModal() {
    this.addTodoModalTarget.classList.remove("hidden");
  }

  hideAddTodoModal() {
    this.addTodoModalTarget.classList.add("hidden");
  }

  openPrereqTodoModal(e) {
    const documentId = e.target.dataset.documentId;
    const documentTitle = e.target.dataset.documentTitle;
    const prerequisiteTitle = e.target.dataset.prerequisiteTitle;

    this.modalSubservientSubtitleTarget.innerHTML = documentTitle;
    this.modalPrerequisiteSubtitleTarget.innerHTML = prerequisiteTitle;
    this.subservientIdInputTarget.value = documentId;
    this.clearSubNoteFieldTarget.value = "";
    this.clearSubDateFieldTarget.value = "";
    this.clearPreNoteFieldTarget.value = "";
    this.clearPreDateFieldTarget.value = "";
    this.prereqTodoModalTarget.classList.remove("hidden");
  }

  hidePrereqTodoModal() {
    this.prereqTodoModalTarget.classList.add("hidden");
  }

  openTodoModal(e) {
    fetch(e.target.dataset.url)
      .then (response => response.text())
      .then(html => Turbo.renderStreamMessage(html))
      .then(this.todoModalTarget.classList.remove("hidden"));
  }

  hideTodoModal(e) {
    this.todoModalTarget.classList.add("hidden");
  }

  openQuizModal(event){
    event.preventDefault();
    let dataset = event.currentTarget.dataset;
    let source = dataset.source;
    this.quizModalTarget.classList.remove("hidden");
    const documentTitle = dataset.documentTitle;
    this.documentTitleQuizModalTarget.innerHTML = documentTitle;
    this.questionsAnsweredQuizModalTarget.innerHTML = dataset.questionsAnswered;
    let percentCorrect = dataset.percentageCorrect;
    let pieDiv = document.getElementById('saq-modal-pie-div');
    pieDiv.style = `--p:${percentCorrect};--c:#1d7360;--b:15px;`;
    this.percentCorrectQuizModalTarget.innerHTML = percentCorrect+ '%';
    let openQuestionLink = document.getElementById("open-question");
    let newOnClick = `analytics.track('open_questions', {page: window.location.href, source: '${source}', case_name: '${documentTitle}'})`;
    openQuestionLink.setAttribute("onclick", newOnClick);
    let quizId = dataset.quizId;
    openQuestionLink.href = openQuestionLink.href.replace(/\/id\//, `/${quizId}/`);
    let resetOpenQuestionLink = document.getElementById("reset-open-question");
    resetOpenQuestionLink.href = resetOpenQuestionLink.href.replace(/\/id\//, `/${quizId}/`);
    if(this.hasQuizIdTarget) {
      this.quizIdTarget.value = quizId;
    }
    if(this.hasQuizNameTarget) {
      this.quizNameTarget.innerHTML = dataset.quizName;
    }
    let saveTodoLik = document.getElementById("save-question-to-todo");
    let newSaveTodoOnClick = `analytics.track('saq_save_to_do', {page: window.location.href, source: '${source}', case_name: '${documentTitle}'})`;
    saveTodoLik.setAttribute("onclick", newSaveTodoOnClick);
    let openQuestionButton = document.getElementById('open-question');
    if (openQuestionButton) {
      openQuestionButton.focus();
    }
    analytics.track('questions_button', {page: window.location.href, source: source, case_name: documentTitle})
  }

  hideQuizModal(){
    this.quizModalTarget.classList.add("hidden");
  }

  openSaveQuizTodoModal(event){
    event.preventDefault();
    this.quizModalTarget.classList.add("hidden");
    this.saveQuizTodoModalTarget.classList.remove("hidden");
    let selectElement = document.getElementsByClassName("select-list-save-quiz-todo-modal")[0];
    selectElement.focus();
  }

  hideSaveQuizTodoModal(){
    this.saveQuizTodoModalTarget.classList.add("hidden");
    if(this.saveQuizTodoModalTarget.dataset.redirectTo) {
      window.location = this.saveQuizTodoModalTarget.dataset.redirectTo;
    }
  }

  openResetModal(e){
    e.preventDefault();
    e.stopImmediatePropagation();
    const resetModalId = e.target.dataset.resetModalId;
    const modal = this.resetModalTargets.find(target => target.id === `reset_modal_${resetModalId}`);
    if (modal) {
        modal.classList.remove("hidden");
    }
  }

  hideResetModal(e){
    const resetModalId = e.target.dataset.resetModalId;
    const modal = this.resetModalTargets.find(target => target.id === `reset_modal_${resetModalId}`);
    if (modal) {
        modal.classList.add("hidden");
    }
  }
}
